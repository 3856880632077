/* global gsap, Power3, Swiper, Plyr */
import Highway from '@dogstudio/highway';

export default class PageAbout extends Highway.Renderer {
  onEnter() {
    if (window.DEVMODE) console.log('Page Documentaires');

    this.DOM = { view: this.wrap.lastElementChild };
    
    this.header = document.querySelector(".Header");
    this.header.dataset.theme = "white";
  }

  onLeaveComplete() {}
}
