/* global gsap, Power3, Swiper, Plyr */
import Highway from '@dogstudio/highway';

export default class PageDocumentaires extends Highway.Renderer {
  onEnter() {
    if (window.DEVMODE) console.log('Page Documentaires');

    this.DOM = {view: this.wrap.lastElementChild};

    this.DOM.filterElems = {
      search_form: $(this.DOM.view).find('#search_documentaries'),
      search_bar: $(this.DOM.view).find('#search_bar'),
      keywords: $(this.DOM.view).find('#keywords'),
      thematic: $(this.DOM.view).find('#thematic'),
      date: $(this.DOM.view).find('#date'),
      director: $(this.DOM.view).find('#director'),
      local_label: $(this.DOM.view).find('#local_label'),
      local: $(this.DOM.view).find('#local'),
      submit_button: $(this.DOM.view).find('#submit_button'),
      more_button: $(this.DOM.view).find('#more'),
      search_result: $(this.DOM.view).find('#search_result'),
    };
    this.ajax_url_search = document.getElementById('search_documentaries').dataset.ajax_url_search;

    this.header = document.querySelector(".Header");
    this.header.dataset.keywords = "white";

    this.posts_per_page = 6;

    this.init_event();
  }

  init_event() {

    this.DOM.filterElems.submit_button.on('click', (e) => {
      e.preventDefault()
      this.search_doc();
    })

    this.DOM.filterElems.local_label.on('click', (e) => {
      e.preventDefault()
      this.toggle_local_check();
    })

    $("body").on('click', "#more", (e) => {
      e.preventDefault()
      this.more_realisations();
    })

  }

  search_doc() {
    let filters = {
      keywords: this.DOM.filterElems.keywords.val(),
      thematic: this.DOM.filterElems.thematic.val(),
      director: this.DOM.filterElems.director.val(),
      date: this.DOM.filterElems.date.val(),
    }
    let keyword = this.DOM.filterElems.search_bar.val();
    let thematic = this.DOM.filterElems.thematic.val();
    let director = this.DOM.filterElems.director.val();
    let local = this.DOM.filterElems.local.is(":checked");

    console.log('duirc' + director)
    // if no filter
    if (keyword.length == 0 && filters.keywords.length == 0 && filters.thematic.length == 0 && filters.director.length == 0 && filters.date.length == 0 && local) {
      var posts_per_page = this.posts_per_page;
    } else {
      var posts_per_page = -1;
    }

    $.ajax({
      type: 'POST',
      url: this.ajax_url_search,
      data: {
        keyword: keyword,
        thematic: thematic,
        director: director,
        posts_per_page: posts_per_page,
        filters: filters,
        local: local,
      },
      success: (data) => {
        this.DOM.filterElems.search_result.html(data);
        gsap.to(window, {
          duration: 1.8,
          scrollTo: {y: this.DOM.filterElems.search_result, offsetY: 0, autoKill: false},
          ease: 'expo.inOut'
        });
      }
    });
  }

  more_realisations() {
    this.posts_per_page += 6;
    this.search_doc();
  }

  toggle_local_check() {
    this.DOM.filterElems.local.attr("checked", !this.DOM.filterElems.local.attr("checked"));
  }


  onLeaveComplete() {
  }
}
