/* global gsap, Power3, Swiper, Plyr */
import Highway from "@dogstudio/highway";
import Form from "../modules/form";

export default class PageContact extends Highway.Renderer {
  onEnter() {
    if (window.DEVMODE) console.log("SingleProjection");

    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.contactForm = document.querySelector(".contact_form");
    this.DOM.contactFormGroups = document.querySelectorAll(".form-group");

    this.events();

    this.header = document.querySelector(".Header");
    this.header.dataset.theme = "white";

    const form = new Form('#contactForm');
  }

  events() {
    const formGroups = [...this.DOM.contactFormGroups];

    formGroups.forEach((formGroup) => {
      const input = formGroup.querySelector('input');
      const inputLogin = formGroup.querySelector('input#login');
      const textarea = formGroup.querySelector('textarea');

      // console.log(inputPassword);


      if (input) {

        input.addEventListener('input', () => {
          // For prerender ids with browsers (cache)
          // > If the input has "login" id

          if (inputLogin) {
            // Adding to password input formGroup the className of "is--active"
            const test = inputLogin.parentNode.parentNode.parentNode.querySelector('.is--password');
            test.classList.add('is--active');
          }

          input.value !== '' && formGroup.classList.add('is--active');

        });

        input.addEventListener('focusin', () => {
          // For prerender ids with browsers (cache)
          // > If the input has "login" id
          if (inputLogin) {
            // Adding to password input formGroup the className of "is--active"
            const test = inputLogin.parentNode.parentNode.parentNode.querySelector('.is--password');
            test.classList.add('is--active');
          }

          input.value === '' && formGroup.classList.add('is--active');

        });

        input.addEventListener('focusout', () => {
          input.value === '' && formGroup.classList.remove('is--active');
        });

      } else if (textarea) {
        textarea.addEventListener('focusin', () => {
          textarea.value === '' && formGroup.classList.add('is--active');
        });
        textarea.addEventListener('focusout', () => {
          textarea.value === '' && formGroup.classList.remove('is--active');
        });
      }
    });
  }

  onLeaveComplete() {}
}
