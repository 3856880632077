/* global gsap, Power3, Swiper, Plyr */
import Highway from '@dogstudio/highway';
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default class SingleDocumentaire extends Highway.Renderer {

  onEnter() {
    if (window.DEVMODE) console.log('SingleProjection');

    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.header = this.DOM.view.querySelector(".Page__header");
    this.DOM.poster = this.DOM.view.querySelector(".Page__header .head_preview");
    this.DOM.documentaireVideo = this.DOM.view.querySelector(".Documentaire__video");
    this.DOM.diaporama = this.DOM.view.querySelector('.Diaporama');
    this.DOM.video = this.DOM.view.querySelector('.Documentaire__video');

    this.DOM.diaporama && this.initDiaporama();
    if (this.DOM.video) this.initPlyr();

    this.header = document.querySelector(".Header");
    this.header.dataset.theme = "white";

    this.togglePoster();
  }

  togglePoster() {
    gsap.registerPlugin(ScrollTrigger);
    
    const { header } = this.DOM;
    const { poster } = this.DOM;
    const headerWrapper = header.querySelector('.wrapper');

    this.pinnedPoster = ScrollTrigger.create({
      id: 'Pinned poster',
      trigger: headerWrapper,
      endTrigger: this.DOM.view,
      start: 'top top',
      end: 'bottom top',
      markers: false,
      onEnter: () => poster.classList.add('pinned--opacity'),
      onEnterBack: () => poster.classList.add('pinned--opacity'),
      onLeave: () => poster.classList.remove('pinned--opacity'),
      onLeaveBack: () => poster.classList.remove('pinned--opacity')
    })
    
    // gsap.to(poster, {
    //   scrollTrigger: {
    //     trigger: header,
    //     start: "top top",
    //     end: "+=700",
    //     toggleActions: "play pause resume reset",
    //     scrub: 1
    //   },
    //   opacity: 0
    // })
  }

  initDiaporama() {
    this.Diaporama = new Swiper(this.DOM.diaporama, {
      virtualTranslate: true,
      navigation: {
        nextEl: document.querySelector('.next'),
        prevEl: document.querySelector('.prev')
      }
    });
  }

  initPlyr() {
    this.plyr = new Plyr(this.DOM.video.querySelector('.plyr__video'));
    this.plyrPlay = this.DOM.video.querySelector('.plyr__icon');

    // Event Play
    this.plyrPlay.addEventListener('click', (e) => this.plyr.play());
  }

  onLeaveComplete() {
    if(this.Diaporama) this.Diaporama.destroy();
    if (this.plyr) this.plyr.destroy();
  }
}
