/* global gsap, Swiper, Plyr */
import Highway from '@dogstudio/highway';

export default class PagePartenaires extends Highway.Renderer {
  onEnter() {
    window.DEVMODE && console.log('Page Partenaires');
    const section = document.querySelector(".Partenaires");

    this.DOM = {
      partenairesCards: section.querySelectorAll(".partenaire"),
      detailsPopin: section.querySelector(".Details")
    }

    this.events();

    this.header = document.querySelector(".Header");
    this.header.dataset.theme = "blue";
  }

  events() {
    // Partenaires blocks
    const partenaires = [...this.DOM.partenairesCards];

    // All Details elements
    const details = this.DOM.detailsPopin;
    const detailsClose = details.querySelector("#close");
    const detailsLogo = details.querySelector(".details_head .logo img");
    const detailsWebsite = details.querySelector(".details_head .website");
    const detailsTitle = details.querySelector(".details_head .head_content h3");
    const detailsSubtitle = details.querySelector(".details_head .head_content .field");
    const detailsEmail = details.querySelector(".details_head .head_content .contact .email");
    const detailsTel = details.querySelector(".details_head .head_content .contact .tel");
    const detailsDescription = details.querySelector(".details_head .head_content .description");
    const detailsSocials = details.querySelector(".details_head .socials");
    
    // projections
    const detailsProjectionsCurrent = details.querySelector(".details_content .projections.is--current .projections_listing");
    const detailsProjectionsPast = details.querySelector(".details_content .projections.is--past .projections_listing");


    partenaires.forEach(partenaire => {
      const partenaireLogo = partenaire.querySelector(".logo img");
      const partenaireWebsite = partenaire.querySelector(".website");
      const partenaireTitle = partenaire.querySelector("h2");
      const partenaireDescription = partenaire.querySelector(".description");
      const partenaireSubtitle = partenaire.querySelector(".field");
      const partenaireEmail = partenaire.querySelector(".contact .email");
      const partenaireTel = partenaire.querySelector(".contact .tel");
      const partenaireSocials = partenaire.querySelector(".socials");

      
      // projections
      const partenaireProjectionsCurrent = partenaire.querySelector(".projections_current");
      const partenaireProjectionsPast = partenaire.querySelector(".projections_past");
      
      partenaire.addEventListener("click", () => {
        details.classList.add("is--showing");
        document.body.style.overflow = "hidden";

        // Add all the content
        // • Logo
        if (detailsLogo) {
          detailsLogo.src = partenaireLogo.src;
          detailsLogo.alt = partenaireLogo.alt;
        } else {
          detailsLogo.classList.add("hide");
        }

        // • Website
        if (partenaireWebsite){
          detailsWebsite.textContent = partenaireWebsite.textContent;
          detailsWebsite.href = partenaireWebsite.href;
        } else {
          detailsWebsite.classList.add("hide");
        }

        // • Title
        partenaireTitle ? (detailsTitle.textContent = partenaireTitle.textContent) : detailsTitle.classList.add("hide");
        partenaireSubtitle ? (detailsSubtitle.textContent = partenaireSubtitle.textContent) : detailsSubtitle.classList.add("hide");
        
        // • Description
        detailsDescription.textContent = partenaireDescription.textContent;

        // • Contact (Email + Tel)
        if (partenaireEmail) {
          detailsEmail.textContent = partenaireEmail.textContent;
          detailsEmail.href = partenaireEmail.href;
        } else {
          detailsEmail.classList.add("hide");
        }

        if (partenaireTel) {
          detailsTel.textContent = partenaireTel.textContent
          detailsTel.href = partenaireTel.href;
        } else {
          detailsTel.classList.add("hide");
        }

        // • Socials
        partenaireSocials ? (detailsSocials.innerHTML = partenaireSocials.innerHTML) : detailsSocials.classList.add("hide");

        // • Projections
        partenaireProjectionsCurrent && (detailsProjectionsCurrent.innerHTML = partenaireProjectionsCurrent.innerHTML);
        partenaireProjectionsCurrent.textContent <= 0 && detailsProjectionsCurrent.parentNode.classList.add("hide");

        partenaireProjectionsPast && (detailsProjectionsPast.innerHTML = partenaireProjectionsPast.innerHTML);
        partenaireProjectionsPast.textContent <= 0 && detailsProjectionsCurrent.parentNode.classList.add("hide");
      })
    });

    // Close the Details Popin
    function closePopin() {
      details.classList.remove("is--showing");
      document.body.style.overflow = "visible";
    }

    // (Cross Button)
    detailsClose.addEventListener("click", closePopin);
    // (ESC keyboard)
    document.body.addEventListener("keydown", e => e.key === "Escape" && closePopin());    
  }

  onLeaveCompleted(){
    if(this.DOM.detailsPopin) this.DOM.detailsPopin.classList.remove('is--showing');
    document.body.style.overflow = "visible";
  }
}
