/* global gsap, Swiper, Plyr */
import Highway from '@dogstudio/highway';
import {buildAjaxUrl} from "utils";

export default class ArchiveProjection extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('ArchiveProjection');
    this.DOM = { view: this.wrap.lastElementChild };

    this.DOM.filterElems = {
      search_form: $(this.DOM.view).find('#search'),
      filter_type_label: $(this.DOM.view).find('.filter_type_label'),
      filter_type: $(this.DOM.view).find('.filter_type'),
      form_control: $(this.DOM.view).find('.form-control'),
      remove_filters: $(this.DOM.view).find('#remove_filters'),
      search_bar: $(this.DOM.view).find('#search_bar'),
      date_picker: $(this.DOM.view).find('#date_picker'),
      date_picker_header : this.DOM.view.querySelector('#date_picker_header'),
      where: $(this.DOM.view).find('#where'),
      theme: $(this.DOM.view).find('#theme'),
      keyword: $(this.DOM.view).find('#keyword'),
    };

    this.DOM.resultElems = {
      articles: $(this.DOM.view).find('.Projection__preview'),
      articles_list: $(this.DOM.view).find('.Projections__list'),
      coming_list: $(this.DOM.view).find('#coming_list'),
      past_list: $(this.DOM.view).find('#past_list'),
      btn_more: $(this.DOM.view).find('#btn_more')
    };

    this.DOM.filterElems.filter_type_label.on('click', (e, i) => {
      this.DOM.filterElems.filter_type.prop('checked', false);
      $(e.target).siblings('input').trigger('click');
    });

    this.DOM.filterElems.form_control.bind('change keyup', () => {
      this.apply_filters();
    });


    this.DOM.resultElems.btn_more.on('click', (e) => {
      e.preventDefault();
      this.DOM.resultElems.btn_more.fadeOut();
      this.DOM.resultElems.articles.fadeIn();
    });

    this.DOM.agendaPage = document.querySelector(".Agenda");
    this.DOM.documentaryMovieMonth = document.querySelector(".is--documentary");

    this.search_result();

    this.init_date_picker();

    this.add_background();

    this.header = document.querySelector(".Header");
    this.header.dataset.theme = "white";
  }

  init_date_picker(){
    const el = $("#date_picker");
    var action = el.data('action');
    var start = el.data('start');
    var end = el.data('end');

    $.ajax({
      type: 'POST',
      url: buildAjaxUrl(action, {start, end}) ,
      data: {},
      success: (data)=> {
        var data = $.parseJSON(data);
        if (data.status == true) {
          this.set_date_picker(data.dates)
        }else {
          console.log(data);
        }
      },
      error: function(data) {
        var data = $.parseJSON(data);
        console.log(data);
      }
    });


  }

  set_date_picker(dates){
    const enabledDates = dates;
    const parentDatePicker =  this.DOM.filterElems.date_picker.parent();
    this.DOM.filterElems.date_picker.datepicker({
      altField: "#date_picker",
      closeText: 'Fermer',
      prevText: 'Précédent',
      nextText: 'Suivant',
      currentText: 'Aujourd\'hui',
      monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
      dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      weekHeader: 'Sem.',
      dateFormat: 'dd/mm/yy',
      beforeShow:function(textbox, instance) {
        parentDatePicker.append($('#ui-datepicker-div'));
      },
      beforeShowDay: function(date) {
        const d = $.datepicker.formatDate( 'dd/mm/yy', date);
        for (var i = 0; i < enabledDates.length; i++) {
          if (d == enabledDates[i]) {
            return [true, 'has-doc'];
          }
        }
        return [true, ''];
      }
    });
  }

  apply_filters() {

    const filters = [];
    let search = '';

    this.DOM.resultElems.btn_more.fadeOut();

    // get filter
    this.DOM.filterElems.form_control.each(function() {
      var value = $(this).val();
      if ($(this).attr('id') == "search_bar") {
        search = value.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-').replace(/^-+/, '').replace(/-+$/, '');
      } else if ($(this).attr("name") === "type") {
        if ($(this).is(":checked") && value != "all") filters.push(value);
      } else {
        if (value != null)
        if (value.length > 0 && value != "all") filters.push(value)
      }
    });

    // no filter
    if (filters.length == 0 && search.length == 0) {
      this.DOM.resultElems.articles.fadeIn()
    }
    if (filters.length > 0) {
      this.DOM.resultElems.articles.each(function(index) {
        var article = $(this);
        var class_article = article.attr('data-search').split(/\s+/)
        $.each(filters, function(index, value) {
          if (class_article.indexOf(value) == -1) {
            article.hide()
          } else {
            article.fadeIn()
          }
        })
      });
    }
    if (search.length > 0) {
      this.DOM.resultElems.articles_list.find(".Projection__preview").not(".Projection__preview[data-search*=" + search + "]").hide();
      this.DOM.resultElems.articles_list.find(".Projection__preview[data-search*=" + search + "]").fadeIn();
    }

    // if (!filters.includes('le-mois-du-film-documentaire')) {
    //   this.DOM.resultElems.articles_list.find(".Projection__preview[data-search*=le-mois-du-film-documentaire]").hide();
    // } else {
    //   this.DOM.resultElems.articles_list.find(".Projection__preview[data-search*=le-mois-du-film-documentaire]").fadeIn();
    // }

    $("#coming_list .Projection__preview:visible").length > 0? $("#coming_list .no-results").hide(): $("#coming_list .no-results").show();
    $("#past_list .Projection__preview:visible").length > 0? $("#past_list .no-results").hide(): $("#past_list .no-results").show();
  }

  search_result() {
    var url = new URL(location.href);
    const parameters = ['type', 'theme', 'keyword', "date", "where", "search_bar"];

    $.each(parameters, (index, value)=> {
      const parameter_value = url.searchParams.get(value);

      if (parameter_value != null) {
        switch (value) {
          case "search_bar":
            console.log(parameter_value);
            this.DOM.filterElems.search_bar.val(parameter_value);
            break;
          case 'type':
            $(`.${parameter_value}`).trigger('click');
            break;
          case 'date':
            this.DOM.filterElems.date_picker.val(parameter_value);
            break;
          case 'where':
            this.DOM.filterElems.where.val(parameter_value);
            break;
          case 'theme':
            this.DOM.filterElems.theme.val(parameter_value);
            break;
          case 'keyword':
            this.DOM.filterElems.keyword.val(parameter_value);
            break;
          default:
        }
        setTimeout(()=> {
          this.apply_filters();
        }, 1000);
      }
    });
  }

  add_background() {}
}
