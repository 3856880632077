import Highway from '@dogstudio/highway';

export default class DefaultPageRenderer extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('new page');
    this.DOM = { view: this.wrap.lastElementChild };

  }

}
