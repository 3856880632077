/* global gsap */
import Highway from '@dogstudio/highway';

export default class DefaultPageTransition extends Highway.Transition {

  in({ from, to, trigger, done }) {

    // Remove Last Page
    if (window.DEVMODE) console.log('Leaving Page : ', from);

    // Timeline animation page enter
    const pageIn = gsap.timeline({
      paused: true,
      defaults: {
        duration: 1.4,
        ease: 'power3.inOut'
      },
      onStart: () => {
        from.style.position = 'absolute';
        document.body.classList.remove('is--loading');
      },
      onComplete: () => {
        from.parentNode.removeChild(from);
        document.body.classList.remove('is--animating');
        done();
      }
    });

    pageIn.to(from, { x: '-30%'}, 0);
    pageIn.fromTo(to, { x: '100%' }, { x: '0%', clearProps: 'all' }, 0);
    pageIn.play();
  }

  out({ from, trigger, done }) {

    // Timeline animation page leave
    const pageOut = gsap.timeline({
      paused: true,
      defaults: {
        duration: 1,
        ease: 'power3.inOut'
      },
      onStart: () => {
        document.body.classList.add('is--loading');
      },
      onComplete: () => {
        done();
      }
    });

    pageOut.to(window, {duration: 1.2, scrollTo: { y: 0, autoKill: false }});
    pageOut.play();
  }
}
