/* global gsap */

export default class pageScroll {

  constructor(container) {

    this.DOM = {
      view: container === document.body ? document.querySelector('.App > *') : container,
      container: container.querySelector('[data-scroll-container]'),
      sections: container.querySelectorAll('[data-scroll-section]'),
      fakeHeight: null
    };

    if (!this.DOM.container || document.body.classList.contains('touch')) return;

    this.state = {
      total: this.DOM.sections.length,
      scroll: {
        current: 0,
        target: 0,
        ease: 0.82
      },
      bounds: {
        height: window.innerHeight,
        scrollHeight: 0,
        threshold: 100
      },
      isResizing: false
    };

    this.sections = null;
    this.onScroll = this.onScroll.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onRender = this.onRender.bind(this);

    this.init();
    this.addListeners();

  }

  init() {

    if (window.DEVMODE) console.log('Init pageScroll');
    this.setupScroll();
    this.setFakeScroll();
    this.setSections();

  }

  setupScroll() {
    const { container } = this.DOM;

    Object.assign(container.style, {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    });

    document.body.classList.add('has-scroll');
  }

  setFakeScroll() {
    const { total, bounds } = this.state;

    if (!this.DOM.fakeHeight) {
      this.DOM.fakeHeight = document.createElement('div');
      this.DOM.fakeHeight.classList.add('Scroll__fake');
      this.DOM.view.appendChild(this.DOM.fakeHeight);
    }

    const { bottom } = this.DOM.sections[total - 1].getBoundingClientRect();
    bounds.scrollHeight = bottom;

    this.DOM.fakeHeight.style.height = `${bottom}px`;
  }

  isVisible(bounds) {
    const { height, threshold } = this.state.bounds;
    const { current } = this.state.scroll;
    const { top, bottom } = bounds;

    const start = top - current;
    const end = bottom - current;
    const isVisible = start < threshold + height && end > -threshold;

    return isVisible;
  }

  setSections() {
    if (!this.DOM.sections) return;
    this.sections = [];

    this.DOM.sections.forEach((el) => {
      el.style.transform = 'translate3d(0, 0, 0)';

      const { top, bottom } = el.getBoundingClientRect();
      const state = {
        el,
        bounds: {
          top,
          bottom
        },
        out: true
      };

      this.sections.push(state);
    });
  }

  scrollSections() {
    const { total, isResizing, scroll } = this.state;
    const translate = `translate3d(0, ${-scroll.current}px, 0)`;

    for (let i = 0; i < total; i++) {
      const data = this.sections[i];
      const { el, bounds } = data;
      const isVisible = this.isVisible(bounds);

      if (isVisible || isResizing) {
        Object.assign(data, { out: false });
        el.style.transform = translate;
      } else if (!data.out) {
        Object.assign(data, { out: true });
        el.style.transform = translate;
      }
    }
  }

  onScroll() {
    const { scroll } = this.state;
    scroll.target = window.pageYOffset || document.documentElement.scrollTop;
  }

  onResize() {
    this.state.isResizing = true;

    if (this.sections) {
      this.sections.forEach(({ el, bounds }) => {
        el.style.transform = 'translate3d(0, 0, 0)';
        const { top, bottom } = el.getBoundingClientRect();
        bounds.top = top;
        bounds.bottom = bottom;
      });
    }

    this.setFakeScroll();
    this.state.isResizing = false;
  }

  onRender() {
    const { scroll } = this.state;

    scroll.current += (scroll.target - scroll.current) * scroll.ease;
    if (scroll.current < 0.1) scroll.current = 0;

    this.scrollSections();
  }

  addListeners() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
    window.addEventListener('resize', this.onResize, { passive: true });
    gsap.ticker.add(this.onRender);
  }

  destroy() {
    if (window.DEVMODE) console.log('Destroy pageScroll');
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
    gsap.ticker.remove(this.onRender);
  }

}
