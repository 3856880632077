/* global gsap, Swiper, Plyr */
import Highway from '@dogstudio/highway';
import Form from '../modules/form';

export default class PageHome extends Highway.Renderer {
  onEnter() {
    if (window.DEVMODE) console.log('PageHome');
    this.DOM = { view: this.wrap.lastElementChild };

    this.initAnchor();

    this.header = document.querySelector('.Header');
    this.header.dataset.theme = 'blue';

    // Newsletter
    this.setNewsletter()

    // News Swiper
    this.DOM.newsSwiper = document.querySelector('.News .swiper');
    if (this.DOM.newsSwiper) {
      this.newsSwiperModule = new Swiper(this.DOM.newsSwiper, {
        centerInsuffisantSlides: true,
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          nextEl: this.DOM.newsSwiper.querySelector('.next'),
          prevEl: this.DOM.newsSwiper.querySelector('.prev')
        },
        autoplay: {
          delay: 5000
        }
      });
    }

    this.linkDocumentary = document.querySelector('.Header .is--documentary');
    this.linkDocumentary && this.linkDocumentary.classList.add('is--home');
  }

  /**
   * Newsletter
   */

  setNewsletter() {
    this.DOM.newsletterFormGroup = document.querySelector('.Newsletter .Form__group')
    this.DOM.newsletterInput = document.querySelector('.Newsletter input');

    // Events
    this.onFocusIn = this.onFocusIn.bind(this)
    this.onFocusOut = this.onFocusOut.bind(this)

    this.DOM.newsletterInput.addEventListener('focusin', this.onFocusIn)
    this.DOM.newsletterInput.addEventListener('focusout', this.onFocusOut)
  }

  onFocusIn(e) {
    this.DOM.newsletterFormGroup.classList.add('is--active');
  }

  onFocusOut(e) {
    if (e.currentTarget.value === '') this.DOM.newsletterFormGroup.classList.remove('is--active');
  }

  /**
   * Anchors
   */

  initAnchor() {
    this.DOM.AnchorsLinks = this.DOM.view.querySelectorAll('[data-anchor]');

    this.DOM.AnchorsLinks.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        const targetAnchor = e.currentTarget.dataset.target;
        gsap.to(window, {
          duration: 1.8,
          scrollTo: { y: targetAnchor, autoKill: false },
          ease: 'expo.inOut'
        });
      });
    });
  }

  /**
   * Leave
   */
  onLeaveCompleted() {
    this.linkDocumentary && this.linkDocumentary.classList.remove('is--home');
    if (this.newsSwiperModule) this.newsSwiperModule.destroy();
  }
}
