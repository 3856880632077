/* global gsap, flatpickr */

export default class Search {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.body = document.querySelector('body');
    this.DOM.toggle = document.querySelector('.Header .Toggle__search');
    this.DOM.close = this.DOM.el.querySelector('.Search__close');
    this.searchOpen = false;
    this.DOM.date_picker_header = $('#date_picker_header');

    this.init();

  }

  init() {

    if (window.DEVMODE) console.log('init Search');

    // Filtres
    $(this.DOM.el).find('.filter_type_label').on('click', (e) => {
      $(this.DOM.el).find('.filter_type').prop('checked', false);
      $(e.currentTarget).siblings('input').trigger('click');
    });

    // Toggle
    this.DOM.toggle.addEventListener('click', (e) => {
      e.preventDefault();
      this.searchOpen === true ? this.close() : this.open();
    });

    // Toggle
    this.DOM.close.addEventListener('click', (e) => {
      e.preventDefault();
      this.close();
    });

    this.init_date_picker();

  }

  init_date_picker(){
      var ajaxurl = this.DOM.date_picker_header.data('ajaxurl');

      $.ajax({
        type: 'POST',
        url: ajaxurl,
        data: {},
        success: (data)=> {
          var data = $.parseJSON(data);
          if (window.DEVMODE) console.log(data);

          if (data.status == true) {
            this.set_date_picker(data.dates)
          }else {
            if (window.DEVMODE) console.log(data);
          }
        },
        error: function(data) {
          var data = $.parseJSON(data);
          console.log(data);
        }
      });
  }

  set_date_picker(dates){
    const enabledDates = dates;
    if (window.DEVMODE) console.log(this.DOM.date_picker_header);
    const parentDatePicker =  this.DOM.date_picker_header.parent();
    this.DOM.date_picker_header.datepicker({
      altField: "#date_picker_header",
      closeText: 'Fermer',
      prevText: 'Précédent',
      nextText: 'Suivant',
      currentText: 'Aujourd\'hui',
      monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
      dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      weekHeader: 'Sem.',
      dateFormat: 'dd/mm/yy',
      beforeShow:function(textbox, instance) {
        parentDatePicker.append($('#ui-datepicker-div'));
      },
      beforeShowDay: function(date) {
        const d = $.datepicker.formatDate( 'dd/mm/yy', date);
        for (var i = 0; i < enabledDates.length; i++) {
          if (d == enabledDates[i]) {
            return [true, 'has-doc'];
          }
        }
        return [true, ''];
      }
    });
  }

  open() {

    return new Promise((resolve, reject) => {

      if (window.DEVMODE) console.log('Search Menu');
      this.searchOpen = true;
      this.DOM.body.classList.add('showSearch');

      const showSearch = gsap.timeline({
        paused: true,
        defaults: {
          duration: 1,
          ease: 'power3.inOut'
        },
        onStart: () => {
          this.DOM.body.style.overflow = 'hidden';
        }
      });

      showSearch.fromTo(this.DOM.el, { x: '10%', opacity: 0}, { x: '0%', opacity: 1, clearProps: 'transform'}, 0);
      showSearch.to(document.querySelector('.App'), { x: '100%'}, 0);
      showSearch.to(document.querySelector('.App > *'), { x: '-50%'}, 0);
      showSearch.play();

    });

  }

  close(withAnimation = true) {

    return new Promise((resolve, reject) => {

      if (window.DEVMODE) console.log('Search Menu');
      this.searchOpen = false;
      this.DOM.body.classList.remove('showSearch');

      if (withAnimation) {

        const closeSearch = gsap.timeline({
          paused: true,
          defaults: {
            duration: 1,
            ease: 'power3.inOut'
          },
          onStart: () => {
            this.DOM.body.style.overflow = '';
          }
        });

        closeSearch.to(this.DOM.el, { x: '10%', opacity: 0, clearProps: 'transform'}, 0);
        closeSearch.to(document.querySelector('.App'), { x: '0%', clearProps: 'transform'}, 0);
        closeSearch.to(document.querySelector('.App > *'), { x: '0%', clearProps: 'transform'}, 0);
        closeSearch.play();

      } else {

        this.DOM.body.style.overflow = '';
        gsap.set(document.querySelector('.App'), { x: '0%', clearProps: 'transform'});
        gsap.set(document.querySelector('.App > *'), { x: '0%', clearProps: 'transform'});

      }

    });

  }

}
