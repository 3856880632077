/* global DEVMODE, gsap */


import FastClick from 'modern-fastclick';
import MicroModal from 'micromodal';

import { newSniff } from './modules/sniffer.js';
import Header from './parts/header.js';
import Footer from './parts/footer.js';
import Search from './parts/search.js';
import Routes from './modules/routes.js';

window.DEVMODE = DEVMODE === 'development';
window.Sniff;

class App {

  constructor() {

    // Declarations
    this.sy = 0;
    this.sniffer;
    this.DOM = {};
    this.DOM.Body = document.body;
    this.DOM.App = this.DOM.Body.querySelector('.App');
    this.DOM.Header = this.DOM.Body.querySelector('.Header');
    this.DOM.Footer = this.DOM.Body.querySelector('.Footer');
    this.DOM.Search = this.DOM.Body.querySelector('#globalSearch');
    this.DOM.Loader = this.DOM.Body.querySelector('.Loader');
    this.DOM.Outdated = this.DOM.Body.querySelector('#Outdated');

    // Signature Wokine
    console.log('%cCreated by Wokine, with ❤', 'color: #000; padding: 5px 0px 1px; border-bottom:2px solid #71d1c2;');
    console.log('%c⚡ http://wokine.com ⚡', 'color:#ccc');

    this.init();
    // this.addEvents();

  }

  init() {

    // DevMode
    if (window.DEVMODE) console.log('🔥 DEVMODE 🔥');

    // Sniffer
    window.Sniff = newSniff();

    // OutdatedBrowser
    if (window.Sniff.browser.name === 'ie' && window.Sniff.browser.majorVersion <= 10) {
      console.log('<= ie010');
      this.DOM.Body.classList.remove('is--first');
      this.DOM.Body.classList.remove('is--loading');
      this.DOM.Outdated.classList.add('show');
      return;
    }

    // Chrome Scroll Manual
    this.DOM.Body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    history.scrollRestoration = 'manual';

    // Create Header/Footer/Routes
    this.Header = new Header(this.DOM.Header);
    this.Search = new Search(this.DOM.Search);
    this.Footer = new Footer(this.DOM.Footer);
    this.Routes = new Routes(this.Header, this.Search);

    // DOMContentLoaded
    document.addEventListener('DOMContentLoaded', () => {

      // Add Fast Click
      new FastClick(document.body);

      if (window.location.hash) {
        const targetElement = document.querySelector(window.location.hash);

        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth'
          });
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        // Reset Scroll
        window.scrollTo(0, 0);
      }

      // Intro
      this.intro();

    }, false);

  }

  addEvents() {
    window.addEventListener('scroll', () => {
      this.sy = window.pageYOffset || document.documentElement.scrollTop;
    });
  }

  initModal() {
    MicroModal.init();
  }

  intro() {

    const intro = gsap.timeline({
      paused: true,
      defaults: {
        duration: 2
      },
      onStart: () => {
        this.DOM.Body.classList.remove('is--first');
        this.DOM.Body.classList.remove('is--loading');
      },
      onComplete: () => {
        this.DOM.Body.classList.remove('is--animating');
        // MicroModal.show('modalCorona');
      },
      delay: 0.6
    });

    intro.fromTo(this.DOM.Loader.querySelector('.brand'), { opacity: 0, scale: 1.2 }, { opacity: 1, scale: 1, ease: 'power3.out', clearProps: 'all' }, 0);
    intro.fromTo(this.DOM.Header, { y: '-100%' }, { y: '0%', ease: 'power3.Out', clearProps: 'all', duration: 1 }, 2);
    intro.fromTo(this.DOM.App, { x: '100%' }, { x: '0%', ease: 'power3.out', clearProps: 'transform', duration: 1 }, 2);
    intro.fromTo(document.querySelector('.Brand__fixed .wrapper'), { y: '-100%' }, { y: '0%', ease: 'power3.out', clearProps: 'transform' }, 2.4);
    intro.play();

  }
}

new App();
