/* global gsap, Power3, Swiper, Plyr */
import Highway from '@dogstudio/highway';

export default class PageAddEvent extends Highway.Renderer {

  onEnter() {
    if (window.DEVMODE) console.log('Add Event page');

    this.user_role = '';

    this.page = $('.Add_event');

    this.DOM = {
      authForm: {
        container_form: this.page.find('.auth_form'),
        form: this.page.find('#login_form'),
        login: this.page.find('#login'),
        password: this.page.find('#password'),
        login_form_submit: this.page.find('#login_form_submit'),
        login_error: this.page.find('#login_error')
      },
      addEventForm: {
        steps_content: this.page.find('#steps_content'),
        form: this.page.find('#addEventForm'),
        agenda_infos_price: this.page.find('#agenda_infos_price'),
        documentary_name: this.page.find('#documentary_name'),
        toogle_free: this.page.find('#toogle_free'),
        addAuthor: this.page.find('#addAuthor'),
        inputFile: this.page.find('.inputFile'),
        deleteFile: this.page.find('.deleteFile')
      },
      body_tag: document.getElementsByTagName('body')[0],
      button_tag: document.getElementById('submit_event'),
      thanks_you: this.page.find('#thanks_you'),
      formGroups: document.querySelectorAll('.form-group'),
      documentaryCheckbox: document.querySelector('.documentary__check input'),
      hiddenForm: document.querySelector('.hidden__form'),
      directors: document.getElementById('directors_array').value,
    };

    this.events();

    // To deploy the entire form
    this.documentaryCheck();

    this.header = document.querySelector('.Header');
    this.header.dataset.theme = 'white';

  }

  events() {

    // Submit authForm
    this.DOM.authForm.form.on('submit', (e) => {
      e.preventDefault();
      this.login_user();
    });

    // Submit addEventForm
    this.DOM.addEventForm.form.on('submit', (e) => {
      e.preventDefault();
      this.addEvent();
    });

    this.DOM.addEventForm.documentary_name.on('input', function () {
      const value = $(this).val();
      const documentary_id = $(`#documentary_name_list [value="${value}"]`).data('customvalue');
      if (documentary_id != undefined || value.length == 0) {
        document.querySelector('#documentary_add').checked = false;
        document.querySelector('#documentary_id').value = documentary_id;
        document.querySelector('.hidden__form').classList.remove('is--visible');

        $('.required_doc').prop('required', false);

      } else {
        document.querySelector('#documentary_add').checked = true;
        document.querySelector('#documentary_id').value = documentary_id;
        document.querySelector('.hidden__form').classList.add('is--visible');

        $('.required_doc').prop('required', true);
      }
    });

    const contact_inputs = $('input[name=agenda_infos_contact_tel], input[name=agenda_infos_contact_email]');
    contact_inputs.on('input', function () {
      contact_inputs.not(this).prop('required', !$(this).val().length);
    });

    this.init_intpu_files();

    // Add author
    this.DOM.addEventForm.addAuthor.on('click', e => {
      e.preventDefault();
      this.addAuthor();
    });

    // if free event input price = 0€
    this.DOM.addEventForm.toogle_free.on('change', (e) => {
      if (this.DOM.addEventForm.toogle_free.is(':checked')) {
        this.DOM.addEventForm.agenda_infos_price.val(0);
        console.log(this.DOM.addEventForm.agenda_infos_price);
        this.DOM.addEventForm.agenda_infos_price[0].parentNode.parentNode.classList.add('is--active');
      } else {
        this.DOM.addEventForm.agenda_infos_price.val('');
        this.DOM.addEventForm.agenda_infos_price[0].parentNode.parentNode.classList.remove('is--active');
      }
    });

    this.floatingLabels();
  }

  init_intpu_files() {
    // Add name file

    console.log('init_intpu_files');
    $('body').on('change', '.inputFile', function () {
      const file_field = $(this);
      const images_nb = file_field[0].files.length;

      if (file_field[0].classList.contains('valideImages')) {
      
        file_field.parent().parent().find('.file_name').removeClass('--error');

        if (images_nb > 5) {

          file_field.val('');
          file_field.parent().parent().find('.file_name').addClass('--error');
          file_field.parent().parent().find('.file_name').text('5 images maximum');
          return;
        }

        const ValidateImg = (file) =>{
          let img = new Image()
          img.src = window.URL.createObjectURL(file)
          img.onload = () => {
              if(img.width === 800 && img.height === 400){
                  console.log("Correct size");
                  return;
              }

              file_field.val('');
              file_field.parent().parent().find('.file_name').addClass('--error');
              file_field.parent().parent().find('.file_name').text("Taille d'image incorrecte");
              console.log("Incorrect size", img.width, img.height);
          }
        }

        [...file_field[0].files].forEach(file => {
          ValidateImg(file)
        })

      }

      let names = '';
      for (let i = 0; i < images_nb; i++) {
        names += `${file_field[0].files[i].name}<br/>`;
      }
      
      file_field.parent().parent().find('.file_name')[0].innerHTML = names;
      // file_field.siblings('.deleteFile').removeClass("hide");
    });
  }


  floatingLabels() {
    const formGroups = [...this.DOM.formGroups];

    formGroups.forEach((formGroup) => {
      const input = formGroup.querySelector('input');
      const inputLogin = formGroup.querySelector('input#login');
      const textarea = formGroup.querySelector('textarea');

      // console.log(inputPassword);


      if (input) {

        input.addEventListener('input', () => {
          // For prerender ids with browsers (cache)
          // > If the input has "login" id

          if (inputLogin) {
            // Adding to password input formGroup the className of "is--active"
            const test = inputLogin.parentNode.parentNode.parentNode.querySelector('.is--password');
            test.classList.add('is--active');
          }

          input.value !== '' && formGroup.classList.add('is--active');

        });

        input.addEventListener('focusin', () => {
          // For prerender ids with browsers (cache)
          // > If the input has "login" id
          if (inputLogin) {
            // Adding to password input formGroup the className of "is--active"
            const test = inputLogin.parentNode.parentNode.parentNode.querySelector('.is--password');
            test.classList.add('is--active');
          }

          input.value === '' && formGroup.classList.add('is--active');

        });

        input.addEventListener('focusout', () => {
          input.value === '' && formGroup.classList.remove('is--active');
        });

      } else if (textarea) {
        textarea.addEventListener('focusin', () => {
          textarea.value === '' && formGroup.classList.add('is--active');
        });
        textarea.addEventListener('focusout', () => {
          textarea.value === '' && formGroup.classList.remove('is--active');
        });
      }
    });
  }

  login_user() {
    this.DOM.authForm.login_error.hide();
    this.wait();
    $.ajax({
      type: 'POST',
      url: this.DOM.authForm.form.data('ajax_url_login'),
      data: {
        login: this.DOM.authForm.login.val(),
        password: this.DOM.authForm.password.val()
      },
      success: (data) => {
        this.wait_done();
        data = JSON.parse(data);
        if (window.DEVMODE) console.log(data);
        if (!data.status) {
          console.log(data.message);
          this.DOM.authForm.login_error.show();
        } else {
          this.user_role = data.user_role;
          console.log(data.message);
          this.DOM.authForm.container_form.hide();
          this.DOM.addEventForm.steps_content.fadeIn();
        }
      }
    });
  }

  addEvent() {

    if (this.user_role.length == 0) {
      console.log('Need loggin');
      return;
    }

    this.wait();
    const formData = new FormData(document.getElementById('addEventForm'));
    $.ajax({
      type: 'POST',
      url: this.DOM.addEventForm.form.data('ajax_url_add_event'),
      data: formData,
      enctype: 'multipart/form-data',
      contentType: false,
      processData: false,
      success: (data) => {
        this.wait_done();
        data = JSON.parse(data);
        if (window.DEVMODE) console.log(data);
        if (!data.status) {
          this.DOM.login_error.show();
        } else {
          this.DOM.addEventForm.steps_content.hide();
          this.DOM.thanks_you.show();
        }
      },
      error: (data) => {
        this.wait_done();
        console.log(data);
      }
    });
  }

  addAuthor() {

    const directors = JSON.parse(this.DOM.directors);
    const directors_ids = [];
    const selected_directors = this.page.find('.directors_id');
    const selected_directors_ids = [];
    const director_form = this.page.find('.director_form_id');
    const director_form_ids = [];

    const checkbox_form = this.page.find('.hide_director_form');

    let component;
    let director_form_id;

    for (const director of directors) {
      directors_ids.push(director.id.toString())
    }

    for (const selected_director of selected_directors) {
      selected_directors_ids.push(selected_director.value)
    }

    for (const director of director_form) {
      director_form_ids.push(director)
    }

    director_form_id = director_form_ids.length + 1

    const filteredDirectors = directors_ids.filter(val => !selected_directors_ids.includes(val));

    for (const director of directors) {
      for (const id of filteredDirectors) {
        if (id && director.id.toString() === id) {
          component += "<option value=\"" + director.id + "\">" + director.title + "</option>"
        }
      }
    }

    const directors_content = `
        <div class="form-group col_12 is--documentary-name is--required is--select">
          <div id="director_select${director_form_id}" class="inner">
            <label>Nom du réalisateur</label>
            <div class="select-input">
              <select name="directors_id[]" id="directors_id" class="form-control directors_id">
              <option value="0">Sélectionner un(e) auteur(e) / un(e) réalisateur(trice)</option>
              ${component}
              </select>
              <div class="select-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8.26 5.19">
                  <path d="M.53.53l3.6,3.6L7.73.53"></path>
                </svg>
              </div>
            </div>
          </div>

        <div id="director_form${director_form_id}">
          <div class="form-group col_6">
          <input class="director_form" type="text" name="directors_name[${director_form_id}]" placeholder="Nom Prénom">
          </div>
          <div class="form-group col_6 is--images">
            <div class="inner">
              <div class="fake-label">
                <span>Portait du réalisateur</span>
                <i class="icn_download">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 12.95 14.08">
                    <path d="M.75,10.47V11.9a1.43,1.43,0,0,0,1.43,1.43h8.59A1.43,1.43,0,0,0,12.2,11.9V10.47"></path>
                    <path d="M10.05,4.33,6.48.75,2.9,4.33"></path>
                    <line x1="6.48" y1="9.76" x2="6.48" y2="0.76"></line>
                  </svg>
                </i>
              </div>
              <label>Uploader une photo</label>
              <input type="file" name="directors_images[${director_form_id}]" class="form-control inputFile" multiple="" accept="image/jpg, image/jpeg">
            </div>
            <p class="file_name"></p>
          </div>
          <div class="form-group col_12">
            <textarea name="directors_description[${director_form_id}]" cols="30" rows="10" placeholder="Description"></textarea>
          </div>
        </div>
        </div>
    `;

    // init new content
    this.init_intpu_files();

    $('#addAuthor').after(directors_content);
  }


  wait() {
    this.DOM.body_tag.style.cursor = 'wait';
    this.DOM.button_tag.style.cursor = 'wait';
    this.DOM.button_tag.disabled = true;
  }

  wait_done() {
    this.DOM.body_tag.style.cursor = 'default';
    this.DOM.button_tag.style.cursor = 'default';
    this.DOM.button_tag.disabled = false;
  }

  documentaryCheck() {
    this.DOM.documentaryCheckbox.addEventListener('change', (e) => {
      e.currentTarget.checked
        ? this.DOM.hiddenForm.classList.add('is--visible')
        : this.DOM.hiddenForm.classList.remove('is--visible');
    });
  }
}
