/* global gsap, Power3, Swiper, Plyr */
import Highway from '@dogstudio/highway';

export default class PageActualites extends Highway.Renderer {
  onEnter() {
    if (window.DEVMODE) console.log('Page Documentaires');

    this.DOM = { view: this.wrap.lastElementChild };

    this.header = document.querySelector(".Header");
    this.header.dataset.theme = "blue";


    var type = new URL(location.href).searchParams.get("type");
    if (type != null) {
      check_filter(type);
      apply_filter(type)
    }

    $('.filter_type_label').on('click',function(e){
      check_filter(this.dataset.type);
      apply_filter(this.dataset.type)
    })

    function check_filter(type){
      $('.filter_type').prop( "checked", false );
      $('#' + type).prop('checked', true);
    }

    function apply_filter(type){
      if (type == "all_type") {
        $('.article_preview').fadeIn();
        return
      }
      $('.article_preview').hide();
      $('.' + type).fadeIn();
    }



  }

  onLeaveComplete() {}
}
